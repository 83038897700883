import { datadogRum } from "@datadog/browser-rum";

const initDatadog = () => {
  let activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "local";
  if (activeEnv === "prod") {
    datadogRum.init({
      applicationId: process.env.GATSBY_DATADOG_APPLICATION_ID || "",
      clientToken: process.env.GATSBY_DATADOG_CLIENT_TOKEN || "",
      site: process.env.GATSBY_DATADOG_SITE,
      service: process.env.GATSBY_DATADOG_SERVICE,
      env: activeEnv,
      version: process.env.BUILD_VERSION,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
    });

    datadogRum.startSessionReplayRecording();
  }
};

export default initDatadog;
