export const windowIsExist = typeof window !== "undefined";

export const scrollBehaviorTo = (y = 0) => {
  const supportsNativeSmoothScroll = "scrollBehavior" in document.documentElement.style;

  if (supportsNativeSmoothScroll) {
    window.scrollTo({
      top: y,
      behavior: "smooth",
    });
  } else {
    window.scrollTo(0, y);
  }
};

export const scrollY = () => {
  //TODO: window.pageYOffset === window.scrollY; always true

  const supportPageOffset = window.pageXOffset !== undefined;
  const isCSS1Compat = (document.compatMode || "") === "CSS1Compat";
  return supportPageOffset
    ? window.pageYOffset
    : isCSS1Compat
    ? document.documentElement.scrollTop
    : document.body.scrollTop;
};

export const buildObjectWithIndex = (objs: { [x: string]: any }) => {
  let a: any = {};
  Object.keys(objs).forEach((key, index) => {
    a[key] = objs[key];
    a[index] = objs[key];
  });
  return a;
};

export const isIEBrowser = () => {
  const ua = window.navigator.userAgent;
  const isIE = /MSIE|Trident/.test(ua);
  return isIE;
};

export const isHTML = (str: string) => {
  if (typeof document !== "undefined") {
    let a = document.createElement("div");
    a.innerHTML = str;

    for (let c = a.childNodes, i = c.length; i--; ) {
      if (c[i].nodeType === 1) return true;
    }

    return false;
  }
};

export const isInAnIframe = () => {
  if (windowIsExist) {
    return window.location !== window.parent.location;
  }

  return false;
};

export const getDefaultUrl = () => (windowIsExist ? window.location.href : "");

export const getUrlParams = (url = getDefaultUrl()) => {
  let params: any = {};
  if (windowIsExist) {
    url.replace(/[?&]+([^=&]+)=([^&]*)/gi, (_: any, key: any, value: string) => {
      params[key] = value;
      return "";
    });
  }

  return params;
};

export const getParamInUrl = (key: string | number, url = getDefaultUrl()) =>
  getUrlParams(url)[key];

export const arrayToQueryParams = (paramsArray: any[]) => {
  let queryParams = "";

  paramsArray.map((item) => {
    if (queryParams === "") {
      queryParams = "?";
    } else {
      queryParams += "&";
    }
    const key = Object.keys(item) as unknown as keyof typeof item;
    const value = item[key];

    queryParams += `${String(key)}=${value}`;
    return queryParams;
  });

  return queryParams;
};

export const isObjectEmpty = (obj: { constructor?: any }) => {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const delay = (ms = 1000) => {
  return new Promise<void>((resolve) => setTimeout(() => resolve(), ms));
};

export const getApiPrefix = () => {
  console.log("GATSBY_ACTIVE_ENV", process.env.GATSBY_ACTIVE_ENV);
  let apiPrefix = process.env.GATSBY_API_BASE_URL || "";

  if (windowIsExist && process.env.GATSBY_ACTIVE_ENV === "prod") {
    const domain = window.location.host.replace("www.", "");
    apiPrefix = `https://api.${domain}/api`;
  } else if (windowIsExist && process.env.GATSBY_ACTIVE_ENV === "local") {
    apiPrefix = `https://local-api.usa-passport.org/api`;
  }

  return apiPrefix;
};
